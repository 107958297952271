/* CSS Hacks. Scrollbars, placeholders etc. */
//@import "lib-components/css-hacks";


$grid-gutter-width: 20px;

/* Bootstrap */
@import '../libs/bootstrap-4.3.1/scss/bootstrap-grid.scss';

$spacer: 80px;
$spacers: ();
$spacers: map-merge(
  ( 
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: ($spacer * .75),
    4: $spacer,
    5: ($spacer * 1.25),
  	6: ($spacer * 1.75)
  ),
  $spacers
);
$light: #656565;

@import '../libs/bootstrap-4.3.1/scss/utilities/_spacing.scss';
// @import '../libs/bootstrap-4.3.1/scss/mixins/_text-truncate.scss';
// @import '../libs/bootstrap-4.3.1/scss/mixins/_hover.scss';
// @import '../libs/bootstrap-4.3.1/scss/mixins/_deprecate.scss';
// @import '../libs/bootstrap-4.3.1/scss/mixins/_text-hide.scss';
// @import '../libs/bootstrap-4.3.1/scss/mixins/_text-emphasis.scss';
// @import '../libs/bootstrap-4.3.1/scss/utilities/_text.scss';

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
} 

/* Slick slider */
@import "lib-components/slick";
@import "../libs/fancybox/dist/jquery.fancybox.min.css";

